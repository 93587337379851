import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import jwtInterceoptor from '../views/shared/jwtInterceptor';

interface UseInputParametersDnDProps {
    setFields: (fields: any[]) => void;
    setSelectedFields: (fields: any[]) => void;
    defaultFields: Field[];
    initialSelectedFields: Field[];
}

interface Field {
    id: string | number;
    content: string;
    locked: boolean;
    type?: string;
}

export const useInputParametersDnD = ({
    setFields,
    setSelectedFields,
    defaultFields,
    initialSelectedFields
}: UseInputParametersDnDProps) => {
    const [newFieldLabel, setNewFieldLabel] = useState('');
    const [newFieldType, setNewFieldType] = useState('');
    const [isRequired, setIsRequired] = useState('Optional');

    const fetchInputParameters = useCallback(async () => {
        try {
            const response = await jwtInterceoptor.get(
                `${process.env.REACT_APP_API_URL}/user/input-parameters-fields/`
            );
            if (response?.data?.data) {
                const transformedData = response.data.data.map((item: any) => ({
                    id: item.id,
                    type: item.structure,
                    locked: item.is_active,
                    content: item.name
                }));

                // Filter out defaultFields that have matching content in transformedData
                const uniqueDefaultFields = defaultFields.filter(defaultField =>
                    !transformedData.some((transformedField: Field) =>
                        transformedField.content.toLowerCase() === defaultField.content.toLowerCase()
                    )
                );

                // Filter unlocked fields from transformedData
                const unlockedTransformedFields = transformedData.filter((field: Field) => !field.locked);

                // Combine unique default fields with transformed data for available fields
                const availableFields = transformedData.length > 0
                    ? [...unlockedTransformedFields]
                    : [...uniqueDefaultFields, ...unlockedTransformedFields];

                let selectedFields = initialSelectedFields;
                if(transformedData.length > 0) {
                    selectedFields = transformedData.filter((field: Field) => field.locked);
                }

                setFields(availableFields);
                setSelectedFields(selectedFields);

                // Return the transformed data length for checking if it's empty
                return transformedData.length;
            }
            return 0; // Return 0 if no data
        } catch (error) {
            toast.error('Failed to fetch input parameters');
            console.error('Error fetching input parameters:', error);
            return 0; // Return 0 on error
        }
    }, [defaultFields, initialSelectedFields, setFields, setSelectedFields]);

    const handleAddCustomField = useCallback(async (payloadItem: any) => {
        if (payloadItem) {
            await jwtInterceoptor.put(
              `${process.env.REACT_APP_API_URL}/user/update-company-fields/`,
              payloadItem
            );
            fetchInputParameters();
        } else {
            toast.error('Please provide both label and type for the custom field.');
        }
    }, [fetchInputParameters]);

    return {
        newFieldLabel,
        setNewFieldLabel,
        newFieldType,
        setNewFieldType,
        isRequired,
        setIsRequired,
        fetchInputParameters,
        handleAddCustomField
    };
};

export default useInputParametersDnD;
