import "./assets/scss/style.scss";
import { ToastContainer } from "react-toastify";
import Login from "./views/pages/login/Login";
import "react-toastify/dist/ReactToastify.css";
import React, { Suspense } from "react";
import Register from "./views/pages/register/Register";
import DefaultLayout from "./views/layout/DefaultLayout";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./views/shared/ProtectedRoute";
import { AuthContextProvider } from "./views/shared/AuthContext";
import ActivateAccount from "./views/pages/activateAccount/ActivateAccount";
import SystemControlCenter from "./components/Project_Extracto/SystemControlCenter/SystemControlCenter";
import { FieldsProvider } from "./contexts/InputParameters/FieldsProvider";
import InputParameters from "./components/Run_Management/InputParameters";
import Onboarding from "./components/Project_Extracto/Onboarding/Onboarding";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Pages
const Home = React.lazy(() => import("./views/Home"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const ForgotPassword = React.lazy(
  () => import("./views/pages/forgotpassword/ForgotPassword")
);
const ResetPassword = React.lazy(
  () => import("./views/pages/resetpassword/ResetPassword")
);
const Settings = React.lazy(() => import("./views/Settings/Settings"));
const Plans = React.lazy(() => import("./views/Plans/plans"));
const Checkout = React.lazy(() => import("./views/Checkout/Checkout"));
const InputParametersDnD = React.lazy(
  () => import("./components/Run_Management/InputParametesDnD")
);
const ExtractoPredictionDashboard = React.lazy(
  () => import("./components/Project_Extracto/ExtractoPredictionDashboard")
);
const ManagementDashboard = React.lazy(
  () =>
    import(
      "./components/Project_Management_Dashboard/Dashboard/ManagementDashboard"
    )
);
const Manufacturing360Overview = React.lazy(
  () =>
    import(
      "./components/Project_Management_Dashboard/Dashboard/Manufacturing360Overview"
    )
);
const FarmApp = React.lazy(
  () => import("./components/Project_Farm_App/FarmApp")
);

const RunData = React.lazy(
  () => import("./components/Run_Management/RunDataV2")
);

class App extends React.Component {
  render() {
    return (
      <>
        <BrowserRouter>
          <AuthContextProvider>
            <Suspense fallback={loading}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <ProtectedRoute accessBy="non-authenticated">
                      <Login />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/Register"
                  element={
                    <ProtectedRoute accessBy="non-authenticated">
                      <Register />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/ForgotPassword"
                  element={
                    <ProtectedRoute accessBy="non-authenticated">
                      <ForgotPassword />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/reset-password/:uid/:token"
                  element={
                    <ProtectedRoute accessBy="non-authenticated">
                      <ResetPassword />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/Home"
                  element={
                    <ProtectedRoute accessBy="authenticated">
                      <DefaultLayout>
                        <Home />
                      </DefaultLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/Settings"
                  element={
                    <ProtectedRoute accessBy="authenticated">
                      <DefaultLayout>
                        <Settings />
                      </DefaultLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/Plans"
                  element={
                    <ProtectedRoute accessBy="authenticated">
                      <DefaultLayout>
                        <Plans />
                      </DefaultLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/SystemControlCenter"
                  element={
                    <ProtectedRoute accessBy="authenticated">
                      <DefaultLayout>
                        <SystemControlCenter />
                      </DefaultLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/InputParameters"
                  element={
                    <ProtectedRoute accessBy="authenticated">
                      <DefaultLayout>
                        <FieldsProvider>
                          <InputParameters />
                        </FieldsProvider>
                      </DefaultLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/InputParametersDnD"
                  element={
                    <ProtectedRoute accessBy="authenticated">
                      <DefaultLayout>
                        <FieldsProvider>
                          <InputParametersDnD />
                        </FieldsProvider>
                      </DefaultLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/Checkout"
                  element={
                    <ProtectedRoute accessBy="authenticated">
                      <DefaultLayout showHeader={false}>
                        <Checkout />
                      </DefaultLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/ExtractoPredictionDashboard"
                  element={
                    <ProtectedRoute accessBy="authenticated">
                      <DefaultLayout>
                        <ExtractoPredictionDashboard />
                      </DefaultLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/FarmApp"
                  element={
                    <ProtectedRoute accessBy="authenticated">
                      <DefaultLayout>
                        <FarmApp />
                      </DefaultLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/ManagementDashboard"
                  element={
                    <ProtectedRoute accessBy="authenticated">
                      <DefaultLayout>
                        <ManagementDashboard />
                      </DefaultLayout>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/Manufacturing-360-Overview"
                  element={
                    <ProtectedRoute accessBy="authenticated">
                      <DefaultLayout>
                        <Manufacturing360Overview />
                      </DefaultLayout>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/RunData"
                  element={
                    <ProtectedRoute accessBy="authenticated">
                      <DefaultLayout>
                        <RunData />
                      </DefaultLayout>
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/activate-account/:id/:token"
                  element={
                    <ProtectedRoute accessBy="non-authenticated">
                      <ActivateAccount />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/onboarding"
                  element={
                    <ProtectedRoute accessBy="authenticated">
                      <FieldsProvider>
                        <Onboarding />
                      </FieldsProvider>
                    </ProtectedRoute>
                  }
                />
                <Route path="*" element={<Page404 />} />
              </Routes>
            </Suspense>
          </AuthContextProvider>
        </BrowserRouter>
        <ToastContainer />
      </>
    );
  }
}

export default App;
