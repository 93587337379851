import React, {
  useState,
  useEffect,
  FormEvent,
  useMemo,
  useCallback,
} from "react";
import { CSpinner, CCol } from "@coreui/react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import NumericInput from "react-numeric-input";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import "../../assets/css/common.css";
import "../../assets/css/inputparameter.css";
import jwtInterceoptor from "../../views/shared/jwtInterceptor";
import useUserProfile from "../../hooks/useUserProfile";
import useCurrentCompanyUsersWithAccessToExtracto from "../../hooks/useCurrentCompanyUsersWithAccessToExtracto";
import { useInputParametersDnD } from "../../hooks/useInputParametersDnD";
import { FaPencilAlt } from "react-icons/fa";
import { cookieUtils } from "../../utils/cookieUtils";

interface Field {
  id: string;
  content: string;
  type: "Text" | "Number" | "Mixed" | "Dropdown" | "Dropdown(Custom)";
  locked: boolean;
  options?: { value: string; label: string }[];
}

interface FormValues {
  input_weight?: number;
  strain_name?: string;
  pressure?: number;
  pressure2?: number;
  temperature?: number;
  temperature2?: number;
  operator?: string;
  batchid?: string;
}

interface FormErrors {
  input_weight?: string;
  strain_name?: string;
  pressure?: string;
  pressure2?: string;
  temperature?: string;
  temperature2?: string;
  operator?: string;
  batchid?: string;
}
interface User {
  id: number;
  first_name?: string;
  last_name?: string;
  email: string;
  name: string;
}

// Update the type to include string literal type for "batch#"
type FormValuesKey = keyof FormValues;

function InputParameters() {
  const navigate = useNavigate();
  const [, setFields] = useState<Field[]>([]);
  const [selectedFields, setSelectedFields] = useState<Field[]>([]);

  const { fetchInputParameters } = useInputParametersDnD({
    setFields,
    setSelectedFields,
    defaultFields: [],
    initialSelectedFields: [],
  });

  // Memoize preview mode check
  const isPreviewMode = cookieUtils.get("preview-mode") || false;
  const currentCompanyUsersWithAccessToExtracto =
    useCurrentCompanyUsersWithAccessToExtracto();
  // Memoize operator options
  const optionsForOperatorSelectField = useMemo(() => {
    if (!currentCompanyUsersWithAccessToExtracto) return [];
    return currentCompanyUsersWithAccessToExtracto.map((user: User) => ({
      label: user.name,
      value: user.id,
    }));
  }, [currentCompanyUsersWithAccessToExtracto]);

  // Modify the useEffect that fetches input parameters
  useEffect(() => {
    console.log("isPreviewMode", isPreviewMode);
    if (isPreviewMode) {
      // In preview mode, load fields from cookies
      const previewFields = cookieUtils.get("preview-selected-fields");
      if (previewFields) {
        const batchField = {
          id: "batchId",
          content: "BatchId",
          type: "Mixed" as const,
          locked: true,
        };
        setSelectedFields([batchField, ...previewFields]);
        console.log("previewFields", previewFields);
      }
    } else {
      // Normal mode - fetch from API and add batch field
      fetchInputParameters().then(() => {
        setSelectedFields((prevFields) => {
          const batchField = {
            id: "batchId",
            content: "BatchId",
            type: "Mixed" as const,
            locked: true,
          };
          return [batchField, ...prevFields];
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Clean up preview mode when component unmounts
  useEffect(() => {
    return () => {
      cookieUtils.remove("preview-mode");
      cookieUtils.remove("preview-selected-fields");
    };
  }, []);

  const initialValues: FormValues = {
    input_weight: 0,
    strain_name: "",
    pressure: 0,
    pressure2: 0,
    temperature: 0,
    temperature2: 0,
    operator: cookieUtils.get("UserName") || "",
    batchid: "",
  };

  const [values, setValues] = useState<FormValues>(initialValues);
  const [errors, setErrors] = useState<FormErrors>({});
  const [loader, setLoader] = useState(false);
  const { userProfile } = useUserProfile();
  const userType: any = userProfile?.role;

  // Handle the redirection
  const handleRedirect = () => {
    navigate("/InputParametersDnD");
  };

  // Optimize handleChange with value comparison
  const handleChange = useCallback((value: any, field: string) => {
    setValues((prevValues) => {
      if (prevValues[field as keyof FormValues] === value) return prevValues;
      return {
        ...prevValues,
        [field]: value,
      };
    });
    setErrors((prevErrors) => {
      if (!prevErrors[field as keyof FormErrors]) return prevErrors;
      return {
        ...prevErrors,
        [field]: "",
      };
    });
  }, []);

  // Update the getFieldKey function to handle special cases
  const getFieldKey = (content: string) => {
    // First handle special cases
    if (content === "Temperature (°C)") return "temperature";
    if (content === "Temperature 2 (°C)") return "temperature2";
    if (content === "Pressure (psi)") return "pressure";
    if (content === "Pressure 2 (psi)") return "pressure2";
    if (content === "Operator") return "operator";

    // For other cases, use the standard transformation
    return content.toLowerCase().replace(/[()°\s]/g, "_");
  };

  // Memoize validation rules to prevent unnecessary re-creations
  const validationRules = useMemo(
    () => ({
      strain_name: {
        condition: (field: Field) =>
          getFieldKey(field.content) === "strain_name",
        validate: (value: string | undefined) =>
          !value?.trim() ? "Strain Name is required" : "",
      },
      temperature: {
        condition: (field: Field) =>
          getFieldKey(field.content) === "temperature",
        validate: (value: number | undefined) => {
          if (value === undefined || value === null)
            return "Temperature is required";
          const temp = Number(value);
          if (isNaN(temp)) return "Temperature must be a valid number";
          if (temp < -100 || temp > -20)
            return "Temperature must be between -100 and -20";
          return "";
        },
      },
      temperature2: {
        condition: (field: Field) =>
          getFieldKey(field.content) === "temperature2",
        validate: (value: number | undefined) => {
          if (value === undefined || value === null)
            return "Temperature 2 is required";
          const temp = Number(value);
          if (isNaN(temp)) return "Temperature must be a valid number";
          if (temp < -100 || temp > -20)
            return "Temperature must be between -100 and -20";
          return "";
        },
      },
      pressure: {
        condition: (field: Field) => getFieldKey(field.content) === "pressure",
        validate: (value: number | undefined) => {
          if (value === undefined || value === null)
            return "Pressure is required";
          const pressure = Number(value);
          if (isNaN(pressure)) return "Pressure must be a valid number";
          if (pressure <= 0) return "Pressure must be greater than 0";
          return "";
        },
      },
      pressure2: {
        condition: (field: Field) => getFieldKey(field.content) === "pressure2",
        validate: (value: number | undefined) => {
          if (value === undefined || value === null)
            return "Pressure 2 is required";
          const pressure = Number(value);
          if (isNaN(pressure)) return "Pressure must be a valid number";
          if (pressure <= 0) return "Pressure must be greater than 0";
          return "";
        },
      },
      input_weight: {
        condition: (field: Field) =>
          getFieldKey(field.content) === "input_weight",
        validate: (value: number | undefined) => {
          if (!value || value <= 0)
            return "Input Weight must be greater than 0";
          return "";
        },
      },
      operator: {
        condition: (field: Field) => getFieldKey(field.content) === "operator",
        validate: (value: string | undefined) =>
          !value?.trim() ? "Operator is required" : "",
      },
      batchid: {
        condition: (field: Field) => field.content === "BatchId",
        validate: (value: string | undefined) =>
          !value?.trim() ? "BatchId is required" : "",
      },
    }),
    []
  );

  // Ensure that validateForm is correctly memoized with its dependencies
  const validateForm = useCallback(
    (formValues: FormValues) => {
      const errors: FormErrors = {};

      selectedFields.forEach((field) => {
        const fieldKey = getFieldKey(field.content) as FormValuesKey;
        const rule = validationRules[fieldKey];

        // console.log("Checking field:", {
        //   content: field.content,
        //   fieldKey,
        //   hasRule: !!rule,
        //   value: formValues[fieldKey as keyof FormValues],
        // });

        if (rule && rule.condition(field)) {
          let value = formValues[fieldKey as keyof FormValues];

          if (field.type === "Number" && typeof value === "string") {
            value = Number(value);
          }

          const error = rule.validate(value as any);
          if (error) {
            errors[fieldKey] = error;
          }
        }
      });

      console.log("Final Validation errors:", errors);
      return errors;
    },
    [selectedFields, validationRules]
  );

  // Update handleSubmit to include the static batch number
  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const validationErrors = validateForm(values);
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }

      try {
        setLoader(true);

        // Extract batchId directly from values
        const { batchid, ...otherValues } = values;

        // Prepare payload with dynamic fields
        const payload = {
          batch_name: batchid,
          operator: values.operator,
          dynamic_fields: Object.entries(otherValues).reduce(
            (acc, [key, value]) => {
              if (
                key !== "batchid" &&
                key !== "operator" &&
                value !== 0 &&
                value !== ""
              ) {
                acc[key.toLowerCase()] = value;
              }
              return acc;
            },
            {} as Record<string, any>
          ),
        };

        const response = await jwtInterceoptor.post(
          `${process.env.REACT_APP_API_URL}/user/api/input-parameters/`,
          payload
        );

        // Batch cookies updates
        const storageUpdates = {
          input_parameter: response?.data?.id,
          run: "true",
        };
        Object.entries(storageUpdates).forEach(([key, value]) =>
          cookieUtils.set(key, value)
        );

        navigate("/ExtractoPredictionDashboard", {
          state: {
            data: {
              strain: values.strain_name,
              input_weight: values.input_weight,
              operator: values.operator,
              pressure: values.pressure,
              temperature: values.temperature,
              batch_number: values.batchid,
              run: true,
            },
          },
        });
      } catch (err) {
        toast.error(
          "Error occurred during processing request. Please contact administrator."
        );
      } finally {
        setLoader(false);
      }
    },
    [values, validateForm, navigate]
  );

  const renderField = (fieldId: string) => {
    const field = selectedFields.find((f: Field) => f.id === fieldId);
    if (!field) return null;
    return renderCustomField(field);
  };

  // Function to render custom fields
  const renderCustomField = (field: Field) => {
    if (!field) return null;

    // Add this helper function to consistently transform field keys
    const fieldKey = getFieldKey(field.content);

    switch (field.type) {
      case "Text":
        return (
          <CCol className="mt-1rem input-width" key={field.id}>
            <label className="form-label" htmlFor={field.id}>
              {field.content}:
            </label>
            <input
              type="text"
              className="form-control"
              value={values[fieldKey as keyof FormValues] || ""}
              onFocus={() =>
                setErrors((prevErrors) => ({ ...prevErrors, [fieldKey]: "" }))
              }
              onChange={(e) => handleChange(e.target.value, fieldKey)}
            />
            {errors[fieldKey as keyof FormErrors] && (
              <p className="error">{errors[fieldKey as keyof FormErrors]}</p>
            )}
          </CCol>
        );

      case "Number":
        return (
          <CCol className="mt-1rem input-width" key={field.id}>
            <label className="form-label" htmlFor={field.id}>
              {field.content}:
            </label>
            <NumericInput
              className="form-control"
              min={field.content.includes("Temperature") ? -100 : 0}
              max={field.content.includes("Temperature") ? -20 : undefined}
              value={values[fieldKey as keyof FormValues] || 0}
              onFocus={() =>
                setErrors((prevErrors) => ({ ...prevErrors, [fieldKey]: "" }))
              }
              onChange={(value) => handleChange(value || 0, fieldKey)}
            />
            {errors[fieldKey as keyof FormErrors] && (
              <p className="error">{errors[fieldKey as keyof FormErrors]}</p>
            )}
          </CCol>
        );

      case "Mixed":
        return (
          <CCol className="mt-1rem input-width" key={field.id}>
            <label className="form-label" htmlFor={field.id}>
              {field.content === "BatchId" ? "Batch#" : field.content}:{" "}
              {field.content === "BatchId" && (
                <span style={{ color: "red" }}>*</span>
              )}
            </label>
            <input
              type="text"
              className="form-control"
              value={values[fieldKey as keyof FormValues] || ""}
              required={field.content === "BatchId"}
              onFocus={() =>
                setErrors((prevErrors) => ({ ...prevErrors, [fieldKey]: "" }))
              }
              onChange={(e) => {
                // Allow alphanumeric characters, hyphens and underscores
                const value = e.target.value.replace(/[^a-zA-Z0-9\-_]/g, "");
                handleChange(value, fieldKey);
              }}
              pattern="[a-zA-Z0-9\-_]*"
              title="Only letters, numbers, hyphens and underscores are allowed"
            />
            {errors[fieldKey as keyof FormErrors] && (
              <p className="error">{errors[fieldKey as keyof FormErrors]}</p>
            )}
          </CCol>
        );

      case "Dropdown":
      case "Dropdown(Custom)":
        return (
          <CCol className="mt-1rem input-width" key={field.id}>
            <label className="form-label" htmlFor={field.id}>
              {field.content}:
            </label>
            <CreatableSelect
              className="classic"
              isSearchable
              options={
                field.type === "Dropdown" ? optionsForOperatorSelectField : []
              }
              onFocus={() =>
                setErrors((prevErrors) => ({ ...prevErrors, [fieldKey]: "" }))
              }
              onChange={(selectedOption) =>
                handleChange(selectedOption?.label || "", fieldKey)
              }
            />
            {errors[fieldKey as keyof FormErrors] && (
              <p className="error">{errors[fieldKey as keyof FormErrors]}</p>
            )}
          </CCol>
        );

      default:
        return null;
    }
  };

  return (
    <div className="input-parameters-container">
      <div className="header-section">
        {userType === "Company Admin" && (
          <div className="customize-link">
            <span onClick={handleRedirect} className="edit-fields-link">
              <FaPencilAlt className="edit-icon" />
              Customize Run Sheet Fields
            </span>
          </div>
        )}
      </div>

      <div className="main-content">
        <div className="input-header">
          <h2 className="page-title">Enter Run Parameters</h2>
          <p className="page-description">
            Fill in Run Sheet before running the extraction process.
          </p>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="fields-grid">
            {selectedFields.map((field: any) => (
              <div key={field.id}>{renderField(field.id)}</div>
            ))}
          </div>

          <div className="form-actions">
            {cookieUtils.get("preview-mode") !== "true" && (
              <Button
                variant="contained"
                type="submit"
                className="submit-button"
                disabled={loader}
              >
                {loader ? (
                  <CSpinner color="success" className="spinner" />
                ) : (
                  "Initiate Run"
                )}
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default InputParameters;
